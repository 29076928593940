import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SEO from '../components/seo'
import Loading from '../components/loading'

import authActions from '../state/auth/actions'
import paymentActions from '../state/payment/actions'

import queryString from 'query-string'

class ChargeResultPage extends React.Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search)
    this.props.onPaymentGetDetail({ id: params.id })
  }

  render() {
    return (
      <div>
        <SEO keywords={[`invitree`]} />
        <Loading />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onPaymentGetDetail: paymentActions.onPaymentGetDetail,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargeResultPage)
